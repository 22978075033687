import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Control } from 'commons/js/ui/Input'
import { required } from 'commons/js/util/validators'

import messages from './messages'

const FirstNameField = ({ intl, ...restProps }) => (
    <Control
      model='.firstName'
      validators={{
          required: required(),
      }}
      placeholder={intl.formatMessage(messages.placeholder)}
      autoComplete='off'
      errorMessages={{
          required: intl.formatMessage(messages.requiredError),
      }}
      debounce={250}
      {...restProps}
    />
)

FirstNameField.propTypes = {
    intl: PropTypes.object,
}

export default injectIntl(FirstNameField)
