// Needed to support async await
import 'regenerator-runtime/runtime'
import App from './App'
import render from 'commons/js/util/render'
import { init } from 'commons/js/util/reporter'
import { getConstant } from 'commons/js/constants'

init()

const element = document.querySelector('.js-login-button')
const dataset = element.dataset

const datasetProps = {
    facebookId: getConstant('FACEBOOK_APP_ID'),
    googleId: getConstant('GOOGLE_APP_ID'),
    redirect: dataset['redirect'],
}

render({ App, element, props: { ...datasetProps } })
