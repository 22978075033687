import { defineMessages } from 'react-intl'

export default defineMessages({
    placeholder: {
        defaultMessage: 'Apellidos',
    },
    requiredError: {
        defaultMessage: 'Obligatorio',
    },
})
