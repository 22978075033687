import { hot } from 'react-hot-loader/root'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { EPProvider, Button } from '@elparking/components'
import convertConstantToEPProviderFormat from 'commons/js/util/coverterGlobalConfig'
import * as api from 'commons/js/api'
import Routes from 'commons/js/routes/routes'
import { messages } from 'commons/js/messages'
import { defaultLocale, currentLocale } from 'commons/js/locale'
import { LoginModal, LoginButton } from '@elparking/login'
import Logo from 'commons/js/ui/Logo'
import theme from './theme'
import { getConstant } from 'commons/js/constants'
import RegisterForm from 'commons/js/ui/RegisterForm'
import CountryForm from 'commons/js/ui/CountryForm/CountryForm'
import { trackLoginSuccessEvent, trackSignupEvent } from 'commons/js/services/analytics'

const App = (props) => {
    const application = getConstant('TRADENAME').trim().toLowerCase()
    return <IntlProvider
      locale={currentLocale}
      defaultLocale={defaultLocale}
      messages={messages[currentLocale]}>
        <EPProvider theme={theme} {...convertConstantToEPProviderFormat()}>
            <LoginButton
              loginModalComponent={LoginModal}
              logo={
                  <a href={Routes.homePath()}>
                      <Logo height='32px' />
                  </a>
              }
              color='gray80'
              fontWeight='book'
              fontSize='large'
              buttonComponent={Button.unstyled}
              application={application}
              userHomePath={Routes.loginPath()}
              homePath={Routes.homePath()}
              requestPasswordPath={Routes.requestPasswordPath()}
              termsAndConditionsPath={Routes.termsAndConditionsPath}
              countryFormElement={CountryForm}
              socialLogin={(params) => api.socialLogin({
                ...params,
                application,
              })}
              trackLoginSuccessEvent={trackLoginSuccessEvent}
              trackSignupEvent={trackSignupEvent}
              registerFormComponent={RegisterForm}
              login={api.login}
              register={api.createAccount}
              getUser={api.getUser}
              userMenu={[]}
              mobileMenuWithoutLogin={[]}
              {...props}
            />
        </EPProvider>
    </IntlProvider>
}

export default hot(App)
